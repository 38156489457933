import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import API from '../utils/api';
import Resizer from 'react-image-file-resizer';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useAlert } from '../context/AlertContext';


const EditProjectPage = () => {
    const { showAlert } = useAlert();

    const { id } = useParams();
    const navigate = useNavigate();
    const [project, setProject] = useState({
        title: '',
        buildDate: null, // Updated to handle date as a Date object
        images: [],
        description: '',
        liveLink: '',
        githubLink: ''
    });

    useEffect(() => {
        const fetchProject = async () => {
            try {
                const res = await API.get(`/projects/${id}`);
                // Convert buildDate from string to Date
                const fetchedProject = res.data;
                if (fetchedProject.buildDate) {
                    fetchedProject.buildDate = new Date(fetchedProject.buildDate);
                }
                setProject(fetchedProject);
            } catch (error) {
                console.error('Failed to fetch project:', error);
            }
        };

        fetchProject();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProject((prevProject) => ({
            ...prevProject,
            [name]: value
        }));
    };

    const handleDateChange = (date) => {
        setProject((prevProject) => ({
            ...prevProject,
            buildDate: date
        }));
    };

    const onDrop = (acceptedFiles) => {
        acceptedFiles.forEach(file => {
            Resizer.imageFileResizer(
                file,
                1280,
                1280,
                'JPEG',
                100,
                0,
                uri => {
                    setProject((prevProject) => ({
                        ...prevProject,
                        images: [...prevProject.images, { file, uri }]
                    }));
                },
                'blob'
            );
        });
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('title', project.title);
        formData.append('buildDate', project.buildDate?.toISOString()); // Convert Date to ISO string
        formData.append('description', project.description);
        formData.append('liveLink', project.liveLink);
        formData.append('githubLink', project.githubLink);

        project.images.forEach((image, index) => {
            if (image.file) {
                formData.append('images', image.file, `image-${index}.jpeg`);
            }
        });

        try {
            await API.put(`/projects/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            showAlert('success', 'Project updated successfully!');
            navigate('/'); // Navigate to homepage after successful update
        } catch (error) {
            showAlert('danger', 'Failed to update project');
            console.error('Error:', error.response || error.message);
        }
    };

    return (
        <div className="container contentBodyLightContainer">
            <h1>Edit Project</h1>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="title">Title</Form.Label>
                            <Form.Control
                                type="text"
                                id="title"
                                name="title"
                                value={project.title}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="buildDate">Build Date</Form.Label>
                            <DatePicker
                                selected={project.buildDate}
                                onChange={handleDateChange}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                className="form-control"
                                placeholderText="Select build date"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="description">Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                id="description"
                                name="description"
                                value={project.description}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="liveLink">Live Link</Form.Label>
                            <Form.Control
                                type="text"
                                id="liveLink"
                                name="liveLink"
                                value={project.liveLink}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="githubLink">GitHub Link</Form.Label>
                            <Form.Control
                                type="text"
                                id="githubLink"
                                name="githubLink"
                                value={project.githubLink}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Images</Form.Label>
                            <div {...getRootProps({ className: 'dropzone' })} className="dropzone">
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop some files here, or click to select files</p>
                            </div>
                            <div className="selected-images">
    {project.images.map((image, index) => (
        <img
            key={index}
            src={typeof image === 'string' ? image : URL.createObjectURL(image.file)}
            alt={`Selected ${index}`}
            className="img-thumbnail mt-2"
            style={{ maxHeight: '100px' }}
        />
    ))}
</div>
                        </Form.Group>
                    </Col>
                </Row>
                <Button type="submit" className="btn btn-primary">Update Project</Button>
            </Form>
        </div>
    );
};

export default EditProjectPage;