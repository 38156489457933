import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/ProjectCard.css';

const ProjectCard = ({ project, deleteProject, user }) => {
    const [showFullDescription, setShowFullDescription] = useState(false);

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };

    const prefetchEditPage = () => {
        import('../pages/EditProjectPage.js').catch(() => {});
    };

    const buildYear = new Date(project.buildDate).getFullYear();

    return (
        <div className="col-12 col-sm-6 col-md-4">
            <div className="card mb-4">
                {project.images && project.images.length > 0 && (
                    <img
                    src={project.images[0]}
                    className="card-img-top"
                    alt={project.title}
                />
                )}
                <div className="card-body">
                    <h5 className="card-title">{project.title}</h5>
                    <p className="buildYearSubtitle">{buildYear}</p>
                    <p className={`card-text ${showFullDescription ? '' : 'line-clamp'}`}>
                        {project.description}
                    </p>
                    {project.description.length > 80 && (
                        <button onClick={toggleDescription} className="btn btn-dark">
                            {showFullDescription ? 'Read Less ▲' : 'Read More ▼'}
                        </button>
                    )}
                    {project.liveLink && (
                        <a href={project.liveLink} className="btn btn-primary" target="_blank" rel="noopener noreferrer">View Live Project</a>
                    )}
                    {project.githubLink && (
                        <a href={project.githubLink} className="btn btn-secondary" target="_blank" rel="noopener noreferrer">View GitHub</a>
                    )}
                    {user && (
                        <>
                            <Link to={`/edit-project/${project._id}`} className="edit-btn" onMouseEnter={prefetchEditPage}>
                            ✎ Edit
                            </Link>
                            <button className="btn btn-danger" onClick={() => deleteProject(project._id)}>Delete</button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

// ✅ Correct usage of React.memo()
export default React.memo(ProjectCard);
