import React from 'react';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="bg-dark text-light mt-auto py-3">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-12 col-md-6 text-center text-md-left mb-2 mb-md-0">
                        © {currentYear}&nbsp;
                        <a className="text-light link-underline link-underline-opacity-0" href="https://madewithhearts.com.au/">Anthony Raudino</a>
                    </div>
                    <div className="col-12 col-md-6 text-center text-md-right">
                        <small className="text-small text-grey opacity-50">Built using PortfolioApp beta. Built in Melbourne by <a className='text-light' target="_blank" rel="noreferrer" href="https://anthonyraudino.com/">Anthony Raudino</a></small>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
