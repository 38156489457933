import React from 'react';
import { useAlert } from '../context/AlertContext';
import '../styles/FloatingAlert.css'; // Ensure you have styles for animations

const FloatingAlert = () => {
    const { alert, hideAlert } = useAlert();

    if (!alert.visible) return null;

    return (
        <div className={`floating-alert alert alert-${alert.type} show`} role="alert">
            {alert.text}
            <button className="close-btn" onClick={hideAlert}>&times;</button>
        </div>
    );
};

export default FloatingAlert;
