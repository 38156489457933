import React from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
import { AlertProvider } from './context/AlertContext';
import FloatingAlert from './components/FloatingAlert';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import AddProjectPage from './pages/AddProjectPage';
import UpdateProfilePage from './pages/UpdateProfilePage';
import EditProjectPage from './pages/EditProjectPage';
import { AuthProvider } from './context/AuthContext';
import Header from './components/Header';
import PrivateRoute from './components/PrivateRoute';
import Footer from './components/Footer';
import './App.css';

const Layout = React.memo(() => {
    return (
        <div className="app-wrapper">
            <Header />
            <div className="container">
                
                <FloatingAlert />
                <div className="app-container">
                    <div className="content-container">
                        <Outlet />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
});


const App = () => {
    return (
        <AuthProvider>
            <AlertProvider>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<HomePage />} />
                        <Route path="login" element={<LoginPage />} />
                        <Route path="dashboard" element={<PrivateRoute><DashboardPage /></PrivateRoute>} />
                        <Route path="about" element={<AboutPage />} />
                        <Route path="contact" element={<ContactPage />} />
                        <Route path="add-project" element={<PrivateRoute><AddProjectPage /></PrivateRoute>} />
                        <Route path="edit-project/:id" element={<PrivateRoute><EditProjectPage /></PrivateRoute>} />
                        <Route path="update-profile" element={<PrivateRoute><UpdateProfilePage /></PrivateRoute>} />
                    </Route>
                </Routes>
            </AlertProvider>
        </AuthProvider>
    );
};

export default App;