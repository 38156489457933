import React, { createContext, useState, useContext, useEffect } from 'react';

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
    const [alert, setAlert] = useState({ type: '', text: '', visible: false });

    const showAlert = (type, text) => {
        setAlert({ type, text, visible: true });

        setTimeout(() => {
            hideAlert();
        }, 500000); // Hide after 5 seconds
    };

    const hideAlert = () => {
        setAlert({ type: '', text: '', visible: false });
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' && alert.visible) {
                hideAlert();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [alert.visible]);

    return (
        <AlertContext.Provider value={{ alert, showAlert, hideAlert }}>
            {children}
        </AlertContext.Provider>
    );
};

export const useAlert = () => useContext(AlertContext);
