import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import UnauthorizedModal from '../components/UnauthorizedModal';
import { useAlert } from '../context/AlertContext';


const LoginPage = () => {
    const { showAlert } = useAlert();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (location.state && location.state.fromDashboard) {
            setShowModal(true);
        }
    }, [location.state]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await login(username, password);
            navigate('/update-profile');
        } catch (error) {
            showAlert('danger', 'Login failed: ' + error.message);
        }
    };

    const handleCloseModal = () => setShowModal(false);

    return (
        <div className="container contentBodyLightContainer">
            <h1>Login</h1>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="username" className="form-label">Username</label>
                    <input
                        type="text"
                        className="form-control"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="password" className="form-label">Password</label>
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <button type="submit" className="btn btn-primary">Login</button>
            </form>
            <UnauthorizedModal show={showModal} handleClose={handleCloseModal} />
        </div>
    );
};

export default LoginPage;
