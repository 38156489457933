import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import API from '../utils/api';
import AuthContext from '../context/AuthContext';
import LazyLoad from '../components/LazyLoad';
import ProjectCard from '../components/ProjectCard'; // Import new component

import '../styles/HomePage.css';

const HomePage = () => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const { user } = useContext(AuthContext);

    const fetchProjects = async () => {
        try {
            const res = await API.get('/projects');
            const sortedProjects = res.data.sort((a, b) => new Date(b.buildDate) - new Date(a.buildDate));
            setProjects(sortedProjects);
        } catch (error) {
            console.error('Failed to fetch projects:', error);
        } finally {
            setLoading(false);
        }
    };    

    const deleteProject = async (id) => {
        if (window.confirm('Are you sure you want to delete this project?')) {
            try {
                await API.delete(`/projects/${id}`);
                setProjects(projects.filter(project => project._id !== id));
                alert('Project deleted successfully');
            } catch (error) {
                console.error('Failed to delete project:', error);
            }
        }
    };

    useEffect(() => {
        fetchProjects();
    }, []);

    return (
        <div className="container contentBodyContainer">
            <h1>Projects</h1>
            {loading ? (
                <p>Loading projects...</p>
            ) : projects.length === 0 ? (
                <div>
                    <i className="fa-thin fa-folder"></i>
                    <h3>No Projects here!</h3>
                    <p>Get started by logging in and adding your projects to your portfolio!</p>
                </div>
            ) : (
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">
                    {projects.map(project => (
                        <LazyLoad key={project._id}>
                            <ProjectCard project={project} deleteProject={deleteProject} user={user} />
                        </LazyLoad>
                    ))}
                </div>
            )}
        </div>
    );
};

export default HomePage;