import React, { useContext, useState, useEffect } from 'react';
import { useAlert } from '../context/AlertContext'; // Import global alert
import AuthContext from '../context/AuthContext';
import API from '../utils/api';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const UpdateProfilePage = () => {
    const { user } = useContext(AuthContext);
    const { showAlert } = useAlert(); // Use global alert system

    const [profile, setProfile] = useState({
        fullName: '',
        email: user.email,
        phoneNumber: '',
        bio: '',
        socialLinks: {
            linkedin: '',
            twitter: '',
            facebook: '',
            github: ''
        }
    });

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const res = await API.get('/user');
                setProfile(res.data);
            } catch (error) {
                console.error('Failed to fetch profile:', error);
            }
        };

        fetchProfile();
    }, [user]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (['linkedin', 'twitter', 'facebook', 'github'].includes(name)) {
            setProfile((prevProfile) => ({
                ...prevProfile,
                socialLinks: {
                    ...prevProfile.socialLinks,
                    [name]: value
                }
            }));
        } else {
            setProfile((prevProfile) => ({
                ...prevProfile,
                [name]: value
            }));
        }
    };

    const handleBioChange = (value) => {
        setProfile((prevProfile) => ({
            ...prevProfile,
            bio: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await API.put('/user', profile);
            showAlert('success', 'Profile updated successfully!');
        } catch (error) {
            showAlert('danger', 'Failed to update profile. Please try again.');
        }
    };

    return (
        <div className="container contentBodyLightContainer position-relative">
            <h1>Update Profile</h1>

            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label htmlFor="fullName" className="form-label">Full Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="fullName"
                                name="fullName"
                                placeholder="John Doe"
                                value={profile.fullName}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email Address</label>
                            <input
                                type="text"
                                className="form-control"
                                id="email"
                                name="email"
                                value={profile.email}
                                disabled
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                            <input
                                type="text"
                                className="form-control"
                                id="phoneNumber"
                                name="phoneNumber"
                                placeholder="0400 100 200"
                                value={profile.phoneNumber}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="bio" className="form-label">Bio</label>
                            <ReactQuill
                                theme="snow"
                                value={profile.bio}
                                onChange={handleBioChange}
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="mb-3">
                            <label htmlFor="linkedin" className="form-label">LinkedIn</label>
                            <input
                                type="text"
                                className="form-control"
                                id="linkedin"
                                name="linkedin"
                                placeholder="https://linkedin.com/in/your-username"
                                value={profile.socialLinks.linkedin}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="twitter" className="form-label">X (Formerly Twitter)</label>
                            <input
                                type="text"
                                className="form-control"
                                id="twitter"
                                name="twitter"
                                placeholder="https://twitter.com/username"
                                value={profile.socialLinks.twitter}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="facebook" className="form-label">Facebook</label>
                            <input
                                type="text"
                                className="form-control"
                                id="facebook"
                                name="facebook"
                                placeholder="https://facebook.com/username"
                                value={profile.socialLinks.facebook}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="github" className="form-label">GitHub</label>
                            <input
                                type="text"
                                className="form-control"
                                id="github"
                                name="github"
                                placeholder="https://github.com/username"
                                value={profile.socialLinks.github}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="text-center mt-4">
                    <button type="submit" className="btn btn-primary">Update Profile</button>
                </div>
            </form>
        </div>
    );
};

export default UpdateProfilePage;
