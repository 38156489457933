import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faTwitter, faFacebook, faGithub } from '@fortawesome/free-brands-svg-icons';
import API from '../utils/api';

const ContactPage = () => {
    const [contactInfo, setContactInfo] = useState({
        email: '',
        socialLinks: {
            linkedin: '',
            twitter: '',
            facebook: '',
            github: ''
        }
    });

    useEffect(() => {
        const fetchContactInfo = async () => {
            try {
                const res = await API.get('/user');
                setContactInfo(res.data);
            } catch (error) {
                console.error('Failed to fetch contact info:', error);
            }
        };

        fetchContactInfo();
    }, []);

    return (
        <div className="container contentBodyLightContainer">
            <h1 className="text-center my-5">Contact Me</h1>
            <div className="card mx-auto p-4 shadow-sm" style={{ maxWidth: '600px' }}>
                <div className="card-body text-center">
                    <h3>Get in Touch</h3>
                    <p>
                        <FontAwesomeIcon icon={faEnvelope} />{' '}
                        <a href={`mailto:${contactInfo.email}`} className="text-decoration-none">
                            {contactInfo.email || 'example@example.com'}
                        </a>
                    </p>
                </div>
            </div>
            <div className="row text-center mt-5">
                <h4 className="mb-4">Connect with me</h4>
                {Object.entries(contactInfo.socialLinks).map(([platform, link]) => (
                    link && (
                        <div key={platform} className="col-3">
                            <a href={link} target="_blank" rel="noopener noreferrer" className="text-dark">
                                <FontAwesomeIcon
                                    icon={
                                        platform === 'linkedin' ? faLinkedin :
                                        platform === 'twitter' ? faTwitter :
                                        platform === 'facebook' ? faFacebook : faGithub
                                    }
                                    size="2x"
                                />
                                <p className="mt-2 text-capitalize">{platform}</p>
                            </a>
                        </div>
                    )
                ))}
            </div>
        </div>
    );
};

export default ContactPage;