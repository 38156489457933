import React, { useContext, useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { FaUserCircle } from 'react-icons/fa';
import { MdMenu, MdClose } from 'react-icons/md';
import '../styles/Header.css';

const Header = () => {
    const { user, profile, logout } = useContext(AuthContext);
    const [menuOpen, setMenuOpen] = useState(false);
    const navbarRef = useRef(null);

    const toggleMenu = () => {
        setMenuOpen((prev) => !prev);
    };

    const closeMenu = () => setMenuOpen(false);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                navbarRef.current &&
                !navbarRef.current.contains(event.target) &&
                !event.target.closest('.menu-toggle')
            ) {
                setTimeout(() => closeMenu(), 100);
            }
        };

        if (menuOpen) {
            setTimeout(() => document.addEventListener('click', handleOutsideClick), 100);
        } else {
            document.removeEventListener('click', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [menuOpen]);

    return (
        <div className="header-wrapper">
            {/* Top Bar */}
            <div className="top-bar">
                <div className="container">
                    <NavLink to="https://madewithhearts.com.au" className="top-logo">
                        PortfolioApp
                    </NavLink>
                    <div className="top-login">
                    {user ? (
                            <div className="user-menu">
                                <FaUserCircle size={20} />
                                <div className="dropdown">
                                    <NavLink to="/update-profile" onClick={closeMenu}>Update Profile</NavLink>
                                    <NavLink to="/add-project" onClick={closeMenu}>Add Project</NavLink>
                                    <button onClick={() => { logout(); closeMenu(); }}>Logout</button>
                                </div>
                            </div>
                        ) : (
                            <NavLink to="/login" className="login-btn" onClick={closeMenu}>
                                Login
                            </NavLink>
                        )}
                    </div>
                </div>
            </div>

            {/* Main Navbar */}
            <header className="custom-navbar" ref={navbarRef}>
                <div className="container">
                    <div className="navbar-brand">
                        <NavLink to="/" className="logo" onClick={closeMenu}>
                        {profile?.fullName || 'Anthony Raudino'}

                        </NavLink>
                    </div>

                    <button className="menu-toggle" onClick={toggleMenu}>
                        {menuOpen ? <MdClose size={24} /> : <MdMenu size={24} />}
                    </button>

                    <nav className={`nav-links ${menuOpen ? 'active' : ''}`}>
                        <NavLink to="/" onClick={closeMenu}>Portfolio</NavLink>
                        <NavLink to="/about" onClick={closeMenu}>About</NavLink>
                        <NavLink to="/contact" onClick={closeMenu}>Contact</NavLink>

                        
                    </nav>
                </div>
            </header>
        </div>
    );
};

export default Header;
